<template>
  <div>
  <canvas ref="canvas"></canvas>
    <div  class="main-container">  
    
    <img :src="require('/public/assets/BG.png')" class="bg">
    <div class="wrapper"> 
      <div class="logo">
        <img :src="require('../assets/logo.png')" >
      </div>

      <h4 class="text-center text-white">LOGIN</h4>
      
      <form v-on:submit.prevent="onLogin" v-if="login.state == 'login'">
        
        <div class="alert alert-danger" v-if="errors.login.show">
              {{ errors.login.msg }}
          </div>
          
         <div class="btn-group email-or-mobile">
            <button type="button" class="btn " v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')">Mobile No.</button>
            <button type="button" class="btn " v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button> 
          </div>

        <div class="form-group" v-if="selected =='email'">
            <label for="formGroupExampleInput">Email Address:</label>
            <input type="text" class="form-control" id="formGroupExampleInput" v-model="login.loginId">
            <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
        </div>

         <div class="form-group mobile-container" v-if="selected =='mobile' ">
          <label for="loginId">Mobile:</label> 
          <div class="input-group mb-3">
          <div class="input-group-prepend">
            <button class="btn" type="button" v-on:click="toggleCountryList">
              <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
              <span class="text-white">{{ mobileCode }}</span>
            </button>
          </div>
          

          <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)">
        </div>

        <div class="country-list" v-if="showCountry">
            <div class="row">
              <div class="col-sm-6" v-for="l in list.mobileCodes" v-on:click="selectCountry(l)">
                <div >
                  <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                </div>

                <div class="country-name"> <span> {{ l.country }} </span> </div>
                
              </div>
              
            </div>
          </div>
           <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
      </div>



        <div class="form-group">
            <label for="exampleFormControlInput1">PIN:</label>
            <!-- <input type="password" class="form-control" id="exampleFormControlInput1" placeholder="" v-model="login.loginPass"> -->
            <div class="input-group mb-3">
              <input type="password" class="form-control" id="inputPass" @keypress="isNumber($event)" maxlength="6" v-model="login.loginPass">
              <div class="input-group-append">
                <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword">{{ hideOrShow }}</button>
              </div>
            </div>
            <p class="help-block" v-if="errors.login.loginPass && errors.login.loginPass !== ''"> {{ errors.login.loginPass }} </p>

            <div>
              <a href="#" v-on:click="toggleLogin('forgot-pass')" class="forgot">Forgot PIN</a>
            </div>
        </div>

        <div id="captcha" class="d-flex justify-content-center mx-auto">
                 <div>
                    <img :src="`data:image/png;base64,${captchaString}`"  id="captchaImg"/>
                </div>
                <div>
                    <input type="text" class="form-control text-center" v-model="captchaSum"  @keypress="isNumber($event)">
                </div>
            </div>



        
        <div ref="googleLoginBtn" />
        <div class="form-group ">
            <button type="submit" class="btn btn-main" id="loginBtn">LOG IN</button>
        </div>

        <div class="form-group ">
            <button type="button" class="btn " v-on:click="gotoSignup" id="signupBtn">SIGN UP</button>
        </div>
        <div class="footer">
          <span>&copy; CXT</span>
        </div>
      </form>



      <form v-on:submit.prevent="onForgotPassword" v-if="login.state == 'forgot-pass'">

        <div class="alert alert-danger" v-if="errors.login.show">
              {{ errors.login.msg }}
          </div>
            <div class="btn-group email-or-mobile">
            <button type="button" class="btn " v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')">Mobile No.</button>
            <button type="button" class="btn " v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button> 
          </div>

        <div class="form-group" v-if="selected =='email'">
            <label for="formGroupExampleInput">Email Address:</label>
            <input type="text" class="form-control" id="formGroupExampleInput" v-model="login.loginId">
            <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
        </div>

         <div class="form-group mobile-container" v-if="selected =='mobile' ">
          <label for="loginId">Mobile:</label> 
          <div class="input-group mb-3">
          <div class="input-group-prepend">
            <button class="btn" type="button" v-on:click="toggleCountryList">
              <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
              <span class="text-white">{{ mobileCode }}</span>
            </button>
          </div>
          

          <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)">
        </div>

        <div class="country-list" v-if="showCountry">
            <div class="row">
              <div class="col-sm-6" v-for="l in list.mobileCodes" v-on:click="selectCountry(l)">
                <div >
                  <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                </div>

                <div class="country-name"> <span> {{ l.country }} </span> </div>
                
              </div>
              
            </div>
          </div>
           <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
      </div>

      <div class="text-center">
          <div class="form-group submit-button text-center pt-3" v-if="!ifResent">
                <button class="btn btn-main" :class="{ 'btn-disabled': isDisabled }" id="forgotBtn">Send </button>
            </div>
          <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
				</div>

            <a href="#" v-on:click="toggleLogin('login')" class="forgot">Back to login</a>

        </form>


    </div>
    
    
  </div>
</div>

</template>

<script>

  import qs from 'querystring'
  import { Particle, microParticle } from '../stardust'

  export default {
    name: 'Login',
    components: {},
    data: function() {
      return  {
        selected: 'mobile',
        country: 'US',
        mobileCode: '1',
        showCountry: false,
        login: {
          state: 'login'
        },
        errors: {
          login: {
            msg: "",
            show: false,
            loginId: "",
            loginPass: ""
          }
        },
        list: {
          mobileCodes: []
        },
        hideOrShow: "Show",
        captchaString: "",
        captchaSum: "",
        captchaImgSrc: "",
        ifResent: false,
        timerInSeconds: 60,
        isDisabled: false,
        particles: [],
        microparticles: [],
        canvas: null,
        ctx: null
      }
    },
    methods: {

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                this.isDisabled = true
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.isDisabled = false
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

    captcha() {
            const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/login/captcha?fontColor=FFFFFF',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.captchaString = response.data

                })
                .catch((error) => {
                    // Error code goes here
                    this.errorMsg = error.response.data.msgText
                });
        },

      onForgotPassword: function() {
     this.errors.login.show = false;
     let loginId = ""; 

        if(this.selected == 'mobile') {
          loginId = this.mobileCode + this.login.loginId
        } else {
          loginId = this.login.loginId
        }
      const params = {
        siteHost: "cxt.coexstar.com",
        loginId: loginId, 
      }

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');
 

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data,
        url: '/cx/processforgotpin',
      };

      this.axios(options)
        .then(() => { 
           this.errors.login.show = true;
              this.errors.login.msg = "Temporary PIN has been sent";
              this.ifResent = true
              this.timerInSeconds = 60
              this.timer();
              setTimeout(() => {
                this.errors.login.show = false;
                }, 2000)
          }).catch((err) => {
               
               this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
                setTimeout(() => {
                this.errors.login.show = false;
                }, 2000)
          })

    },

    resendCode: function() {
     this.errors.login.show = false;
     let loginId = ""; 

        if(this.selected == 'mobile') {
          loginId = this.mobileCode + this.login.loginId
        } else {
          loginId = this.login.loginId
        }
      const params = {
        siteHost: "cxt.coexstar.com",
        loginId: loginId, 
      }

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');
 

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data,
        url: '/cx/processforgotpin',
      };

      this.axios(options)
        .then(() => { 
           this.errors.login.show = true;
              this.errors.login.msg = "Temporary PIN has been sent";
              this.isResent = true
              this.timerInSeconds = 60
              this.timer();
              this.login.loginId = "";
          }).catch((err) => {
               
               this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
          })

    },

       toggleLogin: function(state) {
        this.errors.login.show = false;
        this.errors.login.msg = "";
        this.login.state = state;
      },
      gotoSignup: function(){
        window.location.href = "/#/signup";
      },
      toggleCountryList: function(){
        this.showCountry = !this.showCountry;
      },
      toggleShowPassword: function(){
        this.login.showPassword = !this.login.showPassword;

        if(this.login.showPassword) {
          document.getElementById('inputPass').type = 'text';
          this.hideOrShow = "Hide"
        } else {
          document.getElementById('inputPass').type = 'password';
          this.hideOrShow = "Show"
        }
      },
      toggleLoginOption(selected) {
        this.selected = selected;
        this.login.loginId = "";

        this.errors.login.loginId = '';
        this.errors.login.loginPass = '';

        if(selected == 'email') {
          this.login.loginId = "";
        }
      },
      getMobileCodes: function() {
        this.axios.get('/assets/mobilecodes.json')
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
              console.log(err);

          })
      },
      selectCountry: function(c) {
        this.showCountry = false;
        this.country = c.countryCode;
        this.mobileCode = c.mobileCode; 
      },
      validateEmail: function (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      onLogin: function() {

        let hasError = false;

        if(this.selected == 'mobile') {
            if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {



              this.errors.login.loginId = "Please provide a valid  Mobile no.";
              hasError = true;
            } 

            if(this.login.loginId.length < 10) {

              this.errors.login.loginId = "Please provide a valid  Mobile no.";
              hasError = true;
            } 

            if(this.login.loginId.substring(0,1) === '0') {
              this.errors.login.loginId = "Please remove 0 in front of your mobile number";
              hasError = true;
            }
        } 

         if(this.selected == 'email') {
          if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {



              this.errors.login.loginId = "Please provide a valid Email Address.";
              hasError = true;
            } 

            if(!this.validateEmail(this.login.loginId)) {
              
              this.errors.login.loginId = "Please provide a valid Email Address.";
              hasError = true;
            }
         }
        

        if(this.login.loginPass == '' || this.login.loginPass == undefined || this.login.loginPass == null) {
          this.errors.login.loginPass = "Please provide valid PIN.";
          hasError = true;
        }  else {
           if(this.login.loginPass.length < 6) {
            this.errors.login.loginPass = "PIN must be 6-digits.";
          hasError = true;
          }
        }

        

        if(hasError) {
          return;
        }

        this.errors.login.show = false;

        let loginId = ""; 

        if(this.selected == 'mobile') {
          loginId = this.mobileCode + this.login.loginId
        } else {
          loginId = this.login.loginId
        }
        
        const params = {
          loginHost: "cxt.coexstar.com",
          loginId: loginId,
          pin: this.login.loginPass,
          captcha: this.captchaSum
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
   

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data,
          url: '/cx/login/pin',
        };

        this.axios(options)
          .then((response) => {
              //console.log(response.data.tokenId)
              localStorage.setItem("tokenId",response.data.tokenId); 
              window.location.href = "/";
            }).catch((err) => {
                this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
                 
          })
      },

      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },

       async handleCredentialResponse(response) {
         console.log(response)
         // Put your backend code in here

        //  const params = {
        //   signupHost: "cxt.coexstar.com",
        //   tokenId: response.credential
        // }

        // const data = Object.keys(params)
        //   .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        //   .join('&');
   

        // const options = {
        //   method: 'POST',
        //   headers: { 'content-type': 'application/x-www-form-urlencoded' },
        //   data,
        //   url: '/cx/signup/google',
        // };

        // this.axios(options)
        //   .then((response) => {
        //      console.log(response)
        //     }).catch((err) => {
        //        console.log(err)
                 
        //   })


      },
      
      createParticle() {
        this.particles.push(new Particle(this.ctx, window.innerWidth / 5));
        this.particles.push(new Particle(this.ctx, (window.innerWidth / 2)));
        this.particles.push(new Particle(this.ctx, (window.innerWidth / 5) * 4));
      },
      createMicroParticle() {
        this.microparticles.push(new microParticle(this.ctx, {
          x: 100, // Example option 1
          y: 100, // Example option 2
          // Additional options if needed
        }));
      },
      clear() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      },
      update() {
        this.clear();
        this.particles = this.particles.filter(p => p.move());
        this.microparticles = this.microparticles.filter(mp => mp.move());
        requestAnimationFrame(this.update.bind(this));
      }
    },

    mounted() { 
      this.captcha();
      window.google.accounts.id.initialize({
        client_id: "872172946308-v01v9mdkubj1es31hm8669gb3qe2cnbe.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
        auto_select: true
      })
      window.google.accounts.id.renderButton(
        this.$refs.googleLoginBtn, {
          text: 'Login',
          size: 'large',
          width: '366', // max width 400
          theme: 'outline' // option : filled_black | outline | filled_blue
        }
      ),
      this.canvas = this.$refs.canvas;
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
      this.ctx = this.canvas.getContext('2d');
      document.body.appendChild(this.canvas);
      // Create initial particles
      setInterval(() => {
        this.createParticle();
        // this.createMicroParticle();
        // Randomize particle creation interval if needed
      }, 200);
      this.update();
    }, 

    watch: {

    }
  }
</script>

<style scoped lang="scss">
    canvas {
      position: fixed;
        top: 0;
        left: 0;
        z-index: -1; /* Ensure canvas is behind other content */
        pointer-events: none; /* Allow clicks to go through the canvas */
    }
  .main-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #signupBtn {
    border: 1px solid #b48b41;
    color: #b48b41;
    width: 100%;
  }
  .forgot {
        display: flex;
    justify-content: end;
  }
   a {
    color: #b48b41;
    text-decoration: underline;
  }
  .mobile-container {
      position: relative;
      .default-flag {
        width: 33px;
         margin-right:0.25em;
      }

      .country-list {
          position: absolute;
          background: #ccc;
          color: #333;
          top: 70px;
          padding: 0.5em;
          height: 13em;
          overflow: auto;
              z-index: 3;
          .row {
            margin: 0 !important;
            .col-sm-6 {
                display: flex;
              padding: 0.5em;
              align-items: center;
              cursor: pointer;
              &:hover {
                background: darken(#ccc, 10%);
              }
              .country-name {
                margin-left: 0.5em;
              }

              span {
                font-size: 0.9em;
              }
            }
          }
      }
    }

    .flag-list-img {
      width: 33px;
    }
  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #b48b41;
          border-bottom: 1px solid #b48b41;
        }
      }
 
    }
  .logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    img{
      width: 50px;  
    }
    
  }

  .email-or-mobile {
    button {
      color: #FFF;  
    }
    
  }

  #forgotBtn {
    width: 100%;
  }

  .wrapper {
    background: #1d2132;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    width: 25em;
    margin-top: 1em;
    form {
      color: #FFF;
    }
  }

  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {

    .wrapper {
      padding: 1em !important;
      h4 {
        font-size: 1.25em;
      }

      .email-or-mobile { 

        .btn {
          font-size: 1em;
        }
      }
    }

    button, input {
      font-size: 85%;
    }

    .mobile-container {
      .default-flag {
        width: 25px;
      }
    }
  }
  /*med*/
  @media(max-width: 63.9375em) {
     #app {
      
      padding: 2em;
      font-size: 85%;

    }
    .wrapper {
      padding: 2em;
     
    }
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(max-width: 768px) {
    .wrapper {
      width: 30em;
    }
    #app {
      
      padding: 4em; 

    }
  }

</style>



